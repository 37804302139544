.App {
  display: flex;
  flex-direction: column;
}

.Centering {
  align-items: center;
  justify-content: center;
}

.carousel-indicators li {
  background-color: #ac0ae8;
}
.Heart {
  color: #ac0ae8;
  font-size: 20;
}

.cust-ed-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mote-purple {
  color: #ac0ae8;
}

.mote-black {
  color: black;
}

.navbar-brand img {
  max-height: 100px;
  width: auto;
}

.mote-button {
  background-color: #ac0ae8;
  color: white;
}

.mote-brand {
  font-family: 'DM Sans', sans-serif;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6.mote-brand {
  font-family: 'DM Sans', sans-serif;
}

.mote-blog h3 {
  margin-top: 1em;
}

.mote-brand .btn-primary,
.btn-primary:after {
  color: #fff !important;
  background-color: #ac0ae8;
  border-color: #ac0ae8;
  box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.2);
}

.demo-video {
  border-radius: 2px;
  border-color: #ac0ae8;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.btn-primary:hover {
  color: #fff;
  background-color: #8203b2;
  border-color: #8203b2;
}

.btn-large {
  font-size: 150%;
}

.btn-answer,
btn-answer:active {
  color: #ffffff;
  background-color: #ac0ae8;
  border-color: #ac0ae8 !important;
  box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 0.5em 0.5em 0.5em 0;
  flex-grow: 1;
}

.btn-answer.disabled,
.btn-answer:disabled {
  color: #fff;
  background-color: #b3b5b8;
  border-color: #b3b5b8;
}

.btn-answer:not(:disabled):hover {
  color: #fff;
  background-color: #8203b2;
  border-color: #8203b2;
}

.btn-option {
  color: #3a3a3a;
  background-color: #e9c9f5;
  border-color: #ac0ae8;
  box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 0.5em 0.5em 0.5em 0;
  flex-grow: 1;
}

.card {
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.mote-brand .btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3), 0 0 0 0.2rem #f8e6ff;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #ac0ae8;
  border-color: #ac0ae8;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #8203b2;
  border-color: #8203b2;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem #f8e6ff;
}

.mote-horizontal-nav {
  height: 50px;
}
.mote-horizontal-nav .nav-item + .nav-item {
  border-top-width: 0;
}

.mote-nav-link {
  color: black !important;
  font-size: 24px;
  text-decoration: unset;
  margin: auto !important;
  height: 42px;
}

.teacher-gifting-input {
  padding: 1em 0 1em 0;
}
.mote-boiler-link {
  color: #ac0ae8;
  font-size: inherited;
  text-decoration: unset;
}

.mote-brand .card-featured {
  border-color: #ac0ae8;
}

.mote-brand .btn-outline-primary {
  border-color: #ac0ae8;
  color: #ac0ae8;
}

.mote-brand .btn-outline-primary:hover {
  color: white;
  background-color: #ac0ae8;
}

.mote-brand .custom-select {
  line-height: 1.25;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  border-radius: 0;
  background-color: #f7f7f7;
  max-height: 80px;
  overflow-y: auto;
}

.mote-brand .btn-outline-primary:active {
  background-color: #ac0ae8 !important;
  border-color: #ac0ae8 !important;
}

.mote-brand .form-control:active,
.form-control:focus {
  border-color: #ac0ae8;
}
.mote-brand .card-price {
  border-radius: 6px;
}
.mote-boiler-link:hover {
  color: #ac0ae8;
}

.mote-nav-link:hover {
  color: #ac0ae8;
}

.mote-nav-link:focus {
  color: #ac0ae8;
}

.pricing-column {
  min-width: 120px;
}

.pricing-icons {
  font-size: 40px;
  color: #ac0ae8;
}

.buy-now-link {
  margin: 0 0 0 0;
}

.pricing-cta-row {
  margin: 1em 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buy-now-link a {
  color: white;
  background-color: #ac0ae8;
  text-decoration: unset;
  text-emphasis: bold;
  padding: 0.3em;
  margin: 1em 0 0 0;
  border-radius: 4px;
  box-shadow: 0px 2px 4px 2px rgba(60, 64, 67, 0.15);
}

.buy-now-link-outline {
  margin: 0 0 0 0;
}

.buy-now-link-outline a {
  background-color: white;
  color: #ac0ae8;
  border-color: #ac0ae8;
  text-decoration: unset;
  text-emphasis: bold;
  padding: 0.3em;
  margin: 1em 0 0 0;
  border-radius: 4px;
  box-shadow: 0px 2px 4px 2px rgba(60, 64, 67, 0.15);
}

.buy-now-link-outline a:hover {
  color: white;
  background-color: #ac0ae8;
  text-decoration: unset;
}

.special-offer {
  border-color: #ac0ae8;
  color: white;
  background-color: #19006d;
  text-align: center;
  text-decoration: unset;
  text-emphasis: bold;
  padding: 0.3em;
  margin: 0.5em 0 0 0;
  border-radius: 4px;
  max-width: 280px;
}

.survey-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1em;
}

.blank-offer {
  border-color: white;
  color: white;
  background-color: white;
  text-align: center;
  text-decoration: unset;
  text-emphasis: bold;
  padding: 0.3em;
  margin: 0.5em 0 0 0;
  border-radius: 4px;
  max-width: 280px;
}

.blank-call-us {
  color: white;
  background-color: white;
}

.stripe-logo {
  width: 160px;
  margin: 2em 0 0 0;
}

.pay-button {
  color: white;
  background-color: #ac0ae8;
  text-decoration: unset;
  text-emphasis: bold;
  padding: 0.3em;
  margin: 0.5em 0 0 0.5em;
  border-radius: 4px;
  border-color: transparent;
  min-width: 80px;
  box-shadow: 0px 2px 4px 2px rgba(60, 64, 67, 0.15);
}

.mote-blank-button {
  background-color: transparent;
  border: none;
  width: 300px;
}

.cancel-button {
  color: white;
  background-color: #db0000;
  text-decoration: unset;
  text-emphasis: bold;
  padding: 0.3em;
  margin: 0.5em 0 0 1em;
  border-radius: 4px;
  border-color: transparent;
  min-width: 80px;
  box-shadow: 0px 2px 4px 2px rgba(60, 64, 67, 0.15);
}

.cancel-button a {
  color: white;
  text-decoration: unset;
  text-emphasis: bold;
}

.extend-trial-button {
  color: white;
  background-color: #ac0ae8;
  text-decoration: unset;
  text-emphasis: bold;
  padding: 0.1em 0.3em 0.1em 0.3em;
  margin: 0.1em;
  border-radius: 4px;
  border-color: transparent;
}

.school-name {
  width: 100px;
  max-width: 200px !important;
}

.truncate-school-name {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  white-space: nowrap;
}
.pay-button a {
  color: white;
  text-decoration: unset;
  text-emphasis: bold;
}

.pay-button-row {
  display: flex;
  flex-direction: row-reverse;
}

.mote-pricing-table {
  width: 300px;
}

.buy-now-link a:hover {
  background-color: #65008a;
  text-decoration: unset;
}

.mote-google-docs-link {
  color: black;
  font-size: 20px;
}

.mote-google-docs-link:hover {
  color: #ac0ae8;
}

.mote-vertical-nav .mote-logo {
  margin-left: -20px;
}

.mote-vertical-nav .navbar-toggler {
  padding-top: 9px;
}

.stretched-link:after {
  color: #ac0ae8;
}

.link-cta {
  border-bottom: solid #ac0ae8;
  color: #343a40;
  font-size: 0.875rem;
  letter-spacing: 1px;
  padding-bottom: 2px;
  text-transform: uppercase;
  text-decoration: none;
}

.link-cta:hover {
  color: #ac0ae8;
}

.mote-landing-title {
  padding: 3em 1em 0em;
}

.mote-uninstall-title {
  padding: 1em 0em 0em;
}

.mote-login-lockup {
  padding: 1em 0em 0em;
}

.mote-google-button {
  padding: 1em 1em 8em 1em;
}

.mote-google-landing-button {
  padding: 1em 1em 1em 1em;
}

.agegate-button-young {
  padding: 1em 1em 1em 1em;
  margin: 0em 3em 10em 0em;
}

.agegate-button-old {
  padding: 1em 2em 1em 2em;
  margin: 0em 0em 10em 3em;
}

.mote-login-boilerplate {
  padding: 2em 0em 2em 0em;
  margin: 0em auto 0em auto;
}

.mote-number {
  padding: auto;
  color: #ac0ae8;
  font-size: 48px;
  text-emphasis: emphasis;
}

.mote-login-title {
  padding: 1em 0em 0em;
}
.mote-logged-in-title {
  padding: 1em 0em 0em;
  text-align: left;
}

.mote-logged-in-subtitle {
  padding: 1em 0em 0em;
  text-align: left;
}

.mote-login-text {
  font-weight: 200px;
}
.mote-login-cta {
  padding: 0em 0em 11em 0em;
  font-size: 20px;
}

.mote-user-education {
  padding: 0em 0em 1em 0em;
  font-size: 20px;
  text-align: left;
}

.carousel-headings {
  padding: 0;
  margin: 0;
  font-size: calc(30% + 1vw + 1vh);
}

.carousel-para {
  padding: 0;
  margin: 1em;
}

.mote-carousel next-icon {
  color: black;
}

.mote-carousel prev-icon {
  color: black;
}

.user-ed-gif {
  width: 300px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
}

.mote-login-install-cta {
  font-size: 20px;
}

.mote-chromestore-button {
  width: 250px;
  padding: 0em 0em 4em 0em;
}

.mote-logout {
  padding: 0em 1em 0em;
  font-size: 16px;
}

.mote-logout:hover {
  color: #ac0ae8;
}

.mote-landing-transcript {
  padding: 1em 1em 0em 1em;
  margin: 0px 0px 20px 0px;
}

.mote-landing-audioplayer {
  padding: 3em 1em 0em 1em;
}
.mote-demo-video {
  border: 1px #ac0ae8;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
}

.login-spinner {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.login-spinner > div {
  background-color: #ac0ae8;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.login-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.login-spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.login-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.login-spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.pricing-strike {
  text-decoration: line-through;
  text-decoration-color: red;
}

.pricing-offer-dollar {
  color: #ac0ae8;
  padding-left: 0.5em;
}
.pricing-offer {
  color: #ac0ae8;
}

.login-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.login-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.login-checkbox .mote-boiler-link {
  font-size: 22px;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border-width: 2px;
  border-color: #ac0ae8;
  border-style: solid;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

/* On mouse-over, add a grey background color */
/* .login-checkbox:hover input ~ .checkmark {
	background-color: #ac0ae8;
	color: #FFFFFF;

  }
   */
/* When the checkbox is checked, add a blue background */
.login-checkbox input:checked ~ .checkmark {
  background-color: #ac0ae8;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.login-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.login-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.quote-heart-div {
  text-align: right;
}
.quote-heart {
  color: #ac0ae8;
  font-size: 32px;
  text-align: right;
}

.new-header {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.new-header .brand {
  flex-grow: 1.5;
  padding-left: 20px;
  margin: auto;
}

.new-header .items {
  margin-top: auto;
  margin-bottom: auto;
  flex-grow: 1;
  font-size: 24px;
  /* text-transform: uppercase; */
  justify-content: space-between;
}

.new-header .dropdown-menu {
  width: 250px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.teacher-dropdown {
  height: 42px;
  padding: 9px 0 0 0;
}

.new-header .navbar-text {
  margin: auto !important;
}

.show .nav-item,
.navbar-text,
.nav-link,
.navbar-nav .mote-nav-link,
.navbar-nav .nav-link {
  padding: 0;
  align-items: flex-start;
}

.show .teacher-dropdown {
  height: unset;
}

.new-header .email-link {
  padding: 1em 0 0.5em 2em;
}

.new-header .account-link {
  padding: 0em 0 0.5em 2em !important;
  border: none;
}

.new-header .logout-link {
  padding: 0.5em 0 0.5em 2em !important;
  border: none !important;
}

.new-header .logout-link a,
.navbar-text a {
  color: black !important;
  text-decoration: unset;
}

.new-header a:hover {
  color: #ac0ae8 !important;
}

.new-header a:focus {
  color: #ac0ae8 !important;
}

.teacher-page-intro {
  padding: 0 0 2em 0;
}

.person-img a {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.coupon-box {
  border-color: #65008a !important;
  border-radius: 4px;
  margin: 0 0 1em 0;
}

.coupon-box:focus {
  border-color: #ac0ae8 !important;
}

.focus-visible:focus {
  border-color: #ac0ae8 !important;
}

.coupon-box:after {
  border-color: #ac0ae8 !important;
}

.institution-name {
  width: 410px;
}

.checkout-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.activity-clear {
  color: #db0000;
  vertical-align: -20%;
}

.activity-clear:hover {
  color: #db0000;
  text-decoration: underline;
}

.activity-ID {
  margin-bottom: 2px;
  font-weight: 'bold';
}

.activity-transcript {
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.activity-link {
  color: #ac0ae8;
  text-decoration: underline;
}

.activity-link:hover {
  color: #ac0ae8;
}

.activity-link:hover {
  color: #ac0ae8;
}

.activity-availability {
  color: #ac0ae8;
  text-decoration: underline;
}

.activity-asset {
  min-width: 350px;
}
.activity-status {
  min-width: 150px;
}

.activity-checkbox {
  vertical-align: middle;
}

.activity-row {
  vertical-align: top !important;
}

.activity-row-ago {
  vertical-align: top !important;
  text-transform: capitalize;
}

.activity-created {
  min-width: 166px;
}

.landing-page-reactions {
  padding: 0em 3em 3em 1em;
  height: 40px;
}
.custom-select {
  line-height: 1.25;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  border-radius: 0;
  background-color: #f7f7f7;
  max-height: 80px;
  overflow-y: auto;
}

.activity-table-header {
  margin-bottom: 0px;
  font-weight: bold;
}

.activity-user {
  margin-top: 0px;
  line-height: 25px;
}

.activity-audience {
  font-size: 90%;
  vertical-align: bottom;
}

.activity-engagement {
  margin-top: 0px;
}

.engagement-icons {
  padding-left: 10px;
}

.engagement-emoji {
  font-size: 24px;
  padding-left: 10px;
  vertical-align: 20%;
  /* flex-grow:1; */
}

.mote-green {
  color: #008163;
}

.IKS-seals {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2em;
}

.IKS-seal {
  flex-shrink: 1;
  width: 150px;
  height: 150px;
}

.reaction-emoji {
  font-size: 24px;
  padding-left: 3px;
  padding-right: 3px;
}

.reaction-clicked {
  font-size: 24px;
  margin-left: 10px;
  background-color: #d8d8d8;
  border-color: #d8d8d8;
  border-radius: 4px;
}

.reaction-emoji:hover {
  font-size: 28px;
  background-color: #d8d8d8;
  border-color: #d8d8d8;
  border-radius: 4px;
}

.reaction-emoji:active {
  background-color: #b3b5b8;
  border-color: #b3b5b8;
  border-radius: 4px;
}

.referral-link-box {
  border-color: #6d7278;
  border-width: 1px;
  border-radius: 4px;
}

.refer-clipboard {
  font-size: small;
  margin-left: 2em;
}
.refer-clipboard:hover {
  color: #ac0ae8;
  cursor: pointer;
}

.copied-to-clipboard {
  color: white;
  background-color: #008163;
  text-align: center;
  text-decoration: unset;
  text-emphasis: bold;
  padding: 0.3em;
  margin: 0.5em 0 0 0;
  border-radius: 4px;
  max-width: 280px;
}
